import type { OIDCTokenResponseBody, SessionData } from "@/types.js";

// A PKCEConsumer can get a code challenge to use in the login process
// A PKCEProducer can also generate and store verifiers. The producer must also be a consumer in order to get the challenge from an existing flow
// Examples:
// - Client-only SPA: The SPA generates the code challenge and verifier, stores the verifier in state and returns the code challenge
// Note - The SPA should use PKCEProducer instead to do both
// - Client-side of a client/server app: The client calls the backend to get the challenge.
// - Server-side: The server should generate a new stored verifier and derive the challenge from it.
export interface PKCEConsumer {
  // Retrieve a new PKCE challenge
  getCodeChallenge(): Promise<string>;
}

// All producers are consumers, because the producer can get its own challenge
export interface PKCEProducer extends PKCEConsumer {
  // Retrieve the PKCE challenge from the session if one exists
  getCodeVerifier(): Promise<string | null>;
}

// A service that can initiate requests to login or log out
export interface AuthenticationInitiator {
  // trigger a new login
  signIn(iframeRef: HTMLIFrameElement | null): Promise<URL>;

  // trigger a new logout
  signOut(): Promise<URL>;
}

// A service that can resolve an authentication request according to the OAuth Auth Code grant types
export interface AuthenticationResolver {
  // Given an auth code, get the tokens from the auth server and store them. works in PKCE and non-PKCE environments
  // Note, if we choose later to implement other grants, this method would move into a subinterface specifically
  // for the authorization code grant type.
  // The return type is just for convenience and can be ignored, as the same data would be provided by getSessionData
  tokenExchange(code: string, state: string): Promise<OIDCTokenResponseBody>;

  // If the tokens have already been retrieved, return them
  getSessionData(): Promise<SessionData | null>;

  // If an existing session is found, validate it and return the session data
  validateExistingSession(): Promise<SessionData>;
}

export interface AuthenticationRefresher {
  refreshTokens: () => Promise<OIDCTokenResponseBody>;
}

export class PopupError extends Error {
  constructor(message: string) {
    super(message);
    Object.setPrototypeOf(this, PopupError.prototype);
  }
}
