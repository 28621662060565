"use client";
import { useContext } from "react";
import { SessionContext } from "@/shared/providers/SessionProvider.js";

// TokenProvider will use this internal context to access session
const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within an SessionProvider");
  }
  return context;
};

export { useSession };
