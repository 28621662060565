"use client";
import { useContext } from "react";
import {
  UserContext,
  type UserContextType,
} from "@/shared/providers/UserProvider.js";

const useUser = <
  T extends Record<string, unknown> = Record<string, never>,
>(): UserContextType<T> => {
  const context = useContext(UserContext);

  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }

  return context as UserContextType<T>;
};

export { useUser };
