"use client";
import { useContext } from "react";
import { AuthContext } from "@/shared/providers/AuthContext.js";

const useAuth = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export { useAuth };
