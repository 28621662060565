import type { ForwardedTokens, ForwardedTokensJWT } from "@/types.js";

export const convertForwardedTokenFormat = (
  inputTokens: ForwardedTokensJWT,
): ForwardedTokens =>
  Object.fromEntries(
    Object.entries(inputTokens).map(([source, tokens]) => [
      source,
      {
        idToken: tokens?.id_token,
        accessToken: tokens?.access_token,
        refreshToken: tokens?.refresh_token,
      },
    ]),
  );
