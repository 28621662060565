import type { LoginPostMessage } from "@/types.js";

const validateLoginAppPostMessage = (
  event: LoginPostMessage,
  clientId: string,
): boolean => {
  const caseEvent = event as LoginPostMessage;
  console.log("caseEvent", caseEvent);
  if (
    !caseEvent.clientId ||
    !caseEvent.data.url ||
    !caseEvent.source ||
    !caseEvent.type ||
    caseEvent.clientId !== clientId ||
    caseEvent.source !== "civicloginApp"
  ) {
    return false;
  }
  return true;
};

export { validateLoginAppPostMessage };
