"use client";

import React, {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { Config, DisplayMode, SessionData } from "@/types.js";
import { CivicAuthIframeContainer } from "@/shared/components/CivicAuthIframeContainer.js";
import { TokenProvider } from "@/shared/providers/TokenProvider.js";
import { SessionProvider } from "@/shared/providers/SessionProvider.js";
import { DEFAULT_SCOPES } from "@/constants.js";
import { authConfig } from "@/config.js";
import { LoadingIcon } from "@/shared/components/LoadingIcon.js";
import { isWindowInIframe } from "@/lib/windowUtil.js";
import { AuthContext } from "@/shared/providers/AuthContext.js";
import {
  BrowserAuthenticationInitiator,
  BrowserAuthenticationService,
} from "@/services/AuthenticationService.js";
import type { AuthenticationResolver, PKCEConsumer } from "@/services/types.js";
import { PopupError } from "@/services/types.js";
import { ConfidentialClientPKCEConsumer } from "@/services/PKCE.js";
import { generateState } from "@/lib/oauth.js";
import { LocalStorageAdapter } from "@/browser/storage.js";
import { ConfigProvider } from "@/shared/providers/ConfigProvider.js";
import { getUser } from "../lib/session.js";
import { GenericUserSession } from "../lib/UserSession.js";
import { IframeProvider } from "@/shared/providers/IframeProvider.js";

// Global this object setup
let globalThisObject;
if (typeof window !== "undefined") {
  globalThisObject = window;
} else if (typeof global !== "undefined") {
  globalThisObject = global;
} else {
  globalThisObject = Function("return this")();
}
globalThisObject.globalThis = globalThisObject;

export type AuthProviderProps = {
  children: ReactNode;
  clientId: string;
  nonce?: string;
  onSignIn?: (error?: Error) => void;
  onSignOut?: () => Promise<void>;
  modalIframe?: boolean; // set to false if the iframe is being embedded in the client app
  config?: Config;
  redirectUrl?: string;
};

export type InternalAuthProviderProps = AuthProviderProps & {
  sessionData?: SessionData;
  pkceConsumer?: PKCEConsumer;
};

function BlockDisplay({ children }: { children: ReactNode }) {
  return (
    <div
      id="iframe-block-display-wrapper"
      style={{
        position: "relative",
        left: 0,
        top: 0,
        zIndex: 50,
        display: "flex",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
      }}
    >
      <div
        id="iframe-block-display"
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "white",
        }}
      >
        {children}
      </div>
    </div>
  );
}

const AuthProvider = ({
  children,
  clientId,
  redirectUrl: inputRedirectUrl,
  config = authConfig,
  onSignIn,
  onSignOut,
  pkceConsumer,
  nonce,
  modalIframe = true,
  sessionData: inputSessionData,
}: InternalAuthProviderProps) => {
  const [iframeUrl, setIframeUrl] = useState<string | null>(null);
  const [currentUrl, setCurrentUrl] = useState<string | null>(null);
  const [isInIframe, setIsInIframe] = useState(false);
  const [authResponseUrl, setAuthResponseUrl] = useState<string | null>(null);
  const [tokenExchangeError, setTokenExchangeError] = useState<Error>();
  const [displayMode, setDisplayMode] = useState<DisplayMode>("iframe");
  const [browserAuthenticationInitiator, setBrowserAuthenticationInitiator] =
    useState<BrowserAuthenticationInitiator | null>();
  const [showIFrame, setShowIFrame] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const queryClient = useQueryClient();
  const iframeRef = useRef<HTMLIFrameElement>(null);

  // TODO maybe we want to support or derive serverTokenExchange another way?
  const serverTokenExchange =
    pkceConsumer instanceof ConfidentialClientPKCEConsumer;
  // check if the current window is in an iframe with the iframe id, and set an isInIframe state
  useEffect(() => {
    if (typeof globalThis.window !== "undefined") {
      setCurrentUrl(globalThis.window.location.href);
      const isInIframeVal = isWindowInIframe(globalThis.window);
      setIsInIframe(isInIframeVal);
    }
  }, []);

  const redirectUrl = useMemo(
    () => (inputRedirectUrl || currentUrl || "").split("?")[0],
    [currentUrl, inputRedirectUrl],
  );

  const [authService, setAuthService] = useState<AuthenticationResolver>();

  useEffect(() => {
    if (!currentUrl || !redirectUrl) return;
    BrowserAuthenticationService.build({
      clientId,
      redirectUrl,
      oauthServer: config.oauthServer,
      scopes: DEFAULT_SCOPES,
      displayMode,
    }).then(setAuthService);
  }, [currentUrl, clientId, redirectUrl, config, displayMode]);

  const {
    data: session,
    isLoading,
    error,
  } = useQuery({
    queryKey: [
      "session",
      authResponseUrl,
      iframeUrl,
      currentUrl,
      isInIframe,
      authService,
    ],
    queryFn: async () => {
      if (!authService) {
        return { authenticated: false };
      }
      if (inputSessionData) {
        return inputSessionData;
      }
      const url = new URL(
        authResponseUrl
          ? authResponseUrl
          : globalThis.window.location.href || "",
      );
      // if we have existing tokens, then validate them and return the session data
      // otherwise check if we have a code in the url and exchange it for tokens
      // if we have neither, return undefined
      const existingSessionData = await authService.validateExistingSession();
      if (existingSessionData.authenticated) {
        return existingSessionData;
      }
      const code = url.searchParams.get("code");
      const state = url.searchParams.get("state");
      if (!serverTokenExchange && code && state && !isInIframe) {
        try {
          await authService.tokenExchange(code, state);
          const clientStorage = new LocalStorageAdapter();
          const user = await getUser(clientStorage);
          if (!user) {
            throw new Error("Failed to get user info");
          }

          const userSession = new GenericUserSession(clientStorage);
          userSession.set(user);

          onSignIn?.(); // Call onSignIn without an error if successful
          return authService.getSessionData();
        } catch (error) {
          setTokenExchangeError(error as Error);
          onSignIn?.(
            error instanceof Error ? error : new Error("Failed to sign in"),
          ); // Pass the error to onSignIn
          return { authenticated: false };
        }
      }

      return existingSessionData;
    },
  });

  const signOutMutation = useMutation({
    mutationFn: async () => {
      await onSignOut?.();
      // Implement signOut logic here
      const authInitiator = getAuthInitiator();
      await authInitiator?.signOut();
      setIframeUrl(null);
      setShowIFrame(false);
      setAuthResponseUrl(null);
    },
    onSuccess: () => {
      queryClient.setQueryData(
        [
          "session",
          authResponseUrl,
          iframeUrl,
          currentUrl,
          isInIframe,
          authService,
        ],
        null,
      );
    },
  });

  const getAuthInitiator = useCallback(
    (overrideDisplayMode?: DisplayMode) => {
      const useDisplayMode = overrideDisplayMode || displayMode;

      if (!pkceConsumer || !redirectUrl) {
        return null;
      }

      return new BrowserAuthenticationInitiator({
        pkceConsumer, // generate and retrieve the challenge client-side
        clientId,
        redirectUrl,
        state: generateState(useDisplayMode, serverTokenExchange),
        scopes: DEFAULT_SCOPES,
        displayMode: useDisplayMode,
        oauthServer: config.oauthServer,
        // the endpoints to use for the login (if not obtained from the auth server
        endpointOverrides: config.endpoints,
        nonce,
      });
    },
    [
      serverTokenExchange,
      displayMode,
      clientId,
      redirectUrl,
      config.oauthServer,
      config.endpoints,
      pkceConsumer,
      nonce,
    ],
  );

  const signIn = useCallback(
    async (overrideDisplayMode: DisplayMode = "iframe") => {
      setDisplayMode(overrideDisplayMode);
      const authInitiator = getAuthInitiator(overrideDisplayMode);
      setBrowserAuthenticationInitiator(authInitiator);
      if (overrideDisplayMode === "iframe") {
        setShowIFrame(true);
      } else if (overrideDisplayMode === "redirect") {
        setIsRedirecting(true);
      }

      if (!authInitiator) {
        throw new Error("Failed to get auth initiator");
      }

      authInitiator.signIn(iframeRef.current).catch((error) => {
        console.log("signIn error", {
          error,
          isPopupError: error instanceof PopupError,
        });
        // if we've tried to open a popup and it has failed, then fallback to redirect mode
        if (error instanceof PopupError) {
          signIn("redirect");
        }
      });
    },
    [getAuthInitiator],
  );

  // remove event listeners when the component unmounts
  useEffect(() => {
    return () => {
      if (browserAuthenticationInitiator) {
        browserAuthenticationInitiator.cleanup();
      }
    };
  }, [browserAuthenticationInitiator]);

  const isAuthenticated = useMemo(
    () => (session ? session.authenticated : false),
    [session],
  );

  useQuery({
    queryKey: ["autoSignIn", modalIframe, redirectUrl, isAuthenticated],
    queryFn: async () => {
      if (
        !modalIframe &&
        redirectUrl &&
        !isAuthenticated &&
        iframeRef.current
      ) {
        signIn("iframe");
      }
      return true;
    },
    refetchOnWindowFocus: false,
  });

  const value = useMemo(
    () => ({
      isLoading,
      error: error as Error | null,
      signOut: async () => {
        await signOutMutation.mutateAsync();
      },
      isAuthenticated,
      signIn,
    }),
    [isLoading, error, signOutMutation, isAuthenticated, signIn],
  );

  if (!redirectUrl) return null;

  const showIframeLoadingOverlay =
    modalIframe &&
    (isInIframe || isRedirecting || (isLoading && !serverTokenExchange));
  const showErrorOverlay = tokenExchangeError || error;
  return (
    <AuthContext.Provider value={value}>
      <ConfigProvider
        config={config}
        redirectUrl={redirectUrl}
        modalIframe={modalIframe}
        serverTokenExchange={serverTokenExchange}
      >
        <IframeProvider
          setAuthResponseUrl={setAuthResponseUrl}
          iframeRef={iframeRef}
        >
          <SessionProvider session={session}>
            <TokenProvider>
              {modalIframe && !isInIframe && !session?.authenticated && (
                <div
                  style={
                    showIFrame ? { display: "block" } : { display: "none" }
                  }
                >
                  <CivicAuthIframeContainer
                    onClose={() => setShowIFrame(false)}
                  />
                </div>
              )}

              {showErrorOverlay && (
                <BlockDisplay>
                  <div>
                    Error: {(tokenExchangeError || (error as Error)).message}
                  </div>
                </BlockDisplay>
              )}

              {showIframeLoadingOverlay && !showErrorOverlay && (
                <BlockDisplay>
                  <LoadingIcon />
                </BlockDisplay>
              )}

              {children}
            </TokenProvider>
          </SessionProvider>
        </IframeProvider>
      </ConfigProvider>
    </AuthContext.Provider>
  );
};

export { AuthProvider };
