import type { AuthStorage, ForwardedTokensJWT, User } from "@/types.js";
import { UserStorage } from "@/shared/lib/types.js";
import { convertForwardedTokenFormat } from "@/lib/jwt.js";

export interface UserSession {
  get(): Promise<User | null>;
  set(user: User): Promise<void>;
}

export class GenericUserSession implements UserSession {
  constructor(readonly storage: AuthStorage) {}

  async get(): Promise<User | null> {
    const user = await this.storage.get(UserStorage.USER);
    return user ? JSON.parse(user) : null;
  }

  async set(user: User | null): Promise<void> {
    const forwardedTokens = user?.forwardedTokens
      ? convertForwardedTokenFormat(user?.forwardedTokens as ForwardedTokensJWT)
      : null;
    const value = user ? JSON.stringify({ ...user, forwardedTokens }) : "";
    await this.storage.set(UserStorage.USER, value);
  }
}
