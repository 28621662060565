"use client";
import { authConfig } from "@/config.js";
import type { Config } from "@/types.js";
import type { ReactNode } from "react";
import React, { createContext } from "react";

export type ConfigProviderOutput = {
  config: Config;
  redirectUrl: string;
  modalIframe: boolean;
  serverTokenExchange: boolean;
};
const defaultConfig: ConfigProviderOutput = {
  config: authConfig,
  redirectUrl: "",
  modalIframe: true,
  serverTokenExchange: false,
};
// Context for exposing Config specifically to the TokenProvider
const ConfigContext = createContext<ConfigProviderOutput>(defaultConfig);

type ConfigContextType = {
  children: ReactNode;
  config: Config;
  redirectUrl: string;
  modalIframe?: boolean;
  serverTokenExchange: boolean;
};

const ConfigProvider = ({
  children,
  config,
  redirectUrl,
  modalIframe,
  serverTokenExchange,
}: ConfigContextType) => (
  <ConfigContext.Provider
    value={{
      config,
      redirectUrl,
      modalIframe: !!modalIframe,
      serverTokenExchange,
    }}
  >
    {children}
  </ConfigContext.Provider>
);

export type { ConfigContextType };
export { ConfigProvider, ConfigContext };
