"use client";
import type { SessionData } from "@/types.js";
import type { ReactNode } from "react";
import React, { createContext } from "react";

export type SessionProviderOutput = SessionData;
const defaultSession: SessionProviderOutput = {
  authenticated: false,
  idToken: undefined,
  accessToken: undefined,
  displayMode: "iframe",
};

// Context for exposing session specifically to the TokenProvider
const SessionContext = createContext<SessionProviderOutput>(defaultSession);

type SessionContextType = {
  children: ReactNode;
  session?: SessionData | null;
};

const SessionProvider = ({ children, session }: SessionContextType) => (
  <SessionContext.Provider value={{ ...defaultSession, ...(session || {}) }}>
    {children}
  </SessionContext.Provider>
);

export type { SessionContextType };
export { SessionProvider, SessionContext };
