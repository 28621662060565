import { createContext } from "react";
import type { DisplayMode } from "@/types.js";

export type AuthContextType = {
  signIn: (displayMode?: DisplayMode) => Promise<void>;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: Error | null;
  signOut: () => Promise<void>;
};
export const AuthContext = createContext<AuthContextType | null>(null);
