"use client";
import type { ReactNode } from "react";
import React, { createContext, useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "@/shared/hooks/useAuth.js";
import { useSession } from "@/shared/hooks/useSession.js";
import type { ForwardedTokens, IdToken } from "@/types.js";
import { parseJWT } from "oslo/jwt";
import { convertForwardedTokenFormat } from "@/lib/jwt.js";

type TokenContextType = {
  accessToken: string | null;
  idToken: string | null;
  forwardedTokens: ForwardedTokens;
  refreshToken: () => Promise<void>;
  isLoading: boolean;
  error: Error | null;
};

const TokenContext = createContext<TokenContextType | undefined>(undefined);

const TokenProvider = ({ children }: { children: ReactNode }) => {
  const { isLoading, error: authError } = useAuth();
  const session = useSession();
  const queryClient = useQueryClient();

  const refreshTokenMutation = useMutation({
    mutationFn: async () => {
      // Implement token refresh logic here
      throw new Error("Method not implemented.");
    },
    onSuccess: () => {
      // Invalidate and refetch queries that depend on the auth session
      queryClient.invalidateQueries({ queryKey: ["session"] });
    },
  });

  const decodeTokens = useMemo(() => {
    if (!session?.idToken) return null;

    const parsedJWT = parseJWT(session.idToken) as IdToken | null;

    if (!parsedJWT) return null;

    const { forwardedTokens } = parsedJWT.payload;

    return forwardedTokens
      ? convertForwardedTokenFormat(forwardedTokens)
      : null;
  }, [session?.idToken]);

  const value = useMemo(
    () => ({
      accessToken: session.accessToken || null,
      idToken: session.idToken || null,
      forwardedTokens: decodeTokens || {},
      refreshToken: refreshTokenMutation.mutateAsync,
      isLoading,
      error: (authError || refreshTokenMutation.error) as Error | null,
    }),
    [
      session.accessToken,
      session.idToken,
      decodeTokens,
      refreshTokenMutation.mutateAsync,
      refreshTokenMutation.error,
      isLoading,
      authError,
    ],
  );

  return (
    <TokenContext.Provider value={value}>{children}</TokenContext.Provider>
  );
};

export type { TokenContextType };
export { TokenProvider, TokenContext };
