"use client";
import { useContext } from "react";
import { ConfigContext } from "@/shared/providers/ConfigProvider.js";

// TokenProvider will use this internal context to access Config
const useConfig = () => {
  const context = useContext(ConfigContext);
  if (!context) {
    throw new Error("useConfig must be used within an ConfigProvider");
  }
  return context;
};

export { useConfig };
