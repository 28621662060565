export enum OAuthTokens {
  ID_TOKEN = "id_token",
  ACCESS_TOKEN = "access_token",
  REFRESH_TOKEN = "refresh_token",
}

export enum CodeVerifier {
  COOKIE_NAME = "code_verifier",
  APP_URL = "app_url",
}
export enum UserStorage {
  USER = "user",
}
export interface CookieConfig {
  secure?: boolean;
  sameSite?: "strict" | "lax" | "none";
  domain?: string;
  path?: string;
  maxAge?: number;
  httpOnly?: boolean;
}

export type TokensCookieConfig = Record<
  OAuthTokens | CodeVerifier,
  CookieConfig
>;
