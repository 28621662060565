"use client";

import React, { createContext } from "react";
import type { ReactNode } from "react";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { JWT } from "oslo/jwt";
import type { AuthStorage, EmptyObject, User } from "@/types.js";
import { useAuth } from "@/shared/hooks/useAuth.js";
import { useToken } from "@/shared/hooks/useToken.js";
import { useSession } from "@/shared/hooks/useSession.js";
import type { AuthContextType } from "@/shared/providers/AuthContext.js";
import { GenericUserSession } from "@/shared/lib/UserSession.js";

type UserContextType<
  T extends Record<string, unknown> & JWT["payload"] = Record<string, unknown> &
    JWT["payload"],
> = {
  user: User<T> | null;
} & Omit<AuthContextType, "isAuthenticated">;

const UserContext = createContext<UserContextType | null>(null);

const UserProvider = <T extends EmptyObject>({
  children,
  storage,
  user: inputUser,
  signOut: inputSignOut,
}: {
  children: ReactNode;
  storage: AuthStorage;
  user?: User<T> | null;
  signOut?: () => Promise<void>;
}) => {
  const { isLoading: authLoading, error: authError } = useAuth();
  const session = useSession();
  const { accessToken, idToken } = useToken();
  const { signIn, signOut } = useAuth();

  const fetchUser = async (): Promise<User | null> => {
    if (!accessToken) {
      return null;
    }
    const userSession = new GenericUserSession(storage);
    return userSession.get();
  };

  const {
    data: user,
    isLoading: userLoading,
    error: userError,
  }: UseQueryResult<User<T> | null, Error> = useQuery({
    queryKey: ["user", session?.idToken],
    queryFn: fetchUser,
    enabled: !!session?.idToken, // Only run the query if we have an access token
  });

  const isLoading = authLoading || userLoading;
  const error = authError || userError;

  const userWithIdToken = user ? { ...user, idToken } : null;

  return (
    <UserContext.Provider
      value={{
        user: (inputUser || userWithIdToken) ?? null,
        isLoading,
        error,
        signIn,
        signOut: inputSignOut || signOut,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export type { UserContextType };

export { UserProvider, UserContext };
