"use client";
import { useContext } from "react";
import { IframeContext } from "@/shared/providers/IframeProvider.js";

// TokenProvider will use this internal context to access Iframe
const useIframe = () => {
  const context = useContext(IframeContext);
  if (!context) {
    throw new Error("useIframe must be used within an IframeProvider");
  }
  return context;
};

export { useIframe };
