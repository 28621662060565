// Proposals for revised versions of the SessionService AKA AuthSessionService

import type {
  DisplayMode,
  Endpoints,
  LoginPostMessage,
  OIDCTokenResponseBody,
  SessionData,
} from "@/types.js";
import { BrowserPublicClientPKCEProducer } from "@/services/PKCE.js";
import {
  clearTokens,
  clearUser,
  exchangeTokens,
  generateOauthLoginUrl,
  generateOauthLogoutUrl,
  getEndpointsWithOverrides,
  retrieveTokens,
  storeTokens,
  validateOauth2Tokens,
} from "@/shared/lib/util.js";
import { displayModeFromState, generateState } from "@/lib/oauth.js";
import { OAuth2Client } from "oslo/oauth2";
import { LocalStorageAdapter } from "@/browser/storage.js";
import type {
  AuthenticationInitiator,
  AuthenticationResolver,
  PKCEConsumer,
} from "@/services/types.js";
import { PopupError } from "@/services/types.js";
import { removeParamsWithoutReload } from "@/lib/windowUtil.js";
import { DEFAULT_OAUTH_GET_PARAMS } from "@/constants.js";
import { validateLoginAppPostMessage } from "@/lib/postMessage.js";

/**
 * An authentication initiator that works on a browser. Since this is just triggering
 * login and logout, session data is not stored here.
 * An associated AuthenticationResolver would be needed to get the session data.
 * Storage is needed for the code verifier, this is the domain of the PKCEConsumer
 * The storage used by the PKCEConsumer should be available to the AuthenticationResolver.
 *
 * Example usage:
 *
 * 1) Client-only SPA -eg a react app with no server:
 * new BrowserAuthenticationInitiator({
 *   pkceConsumer: new BrowserPublicClientPKCEProducer(), // generate and retrieve the challenge client-side
 *   ... other config
 * })
 *
 * 2) Client-side of a client/server app - eg a react app with a backend:
 * new BrowserAuthenticationInitiator({
 *  pkceConsumer: new ConfidentialClientPKCEConsumer("https://myserver.com/pkce"), // get the challenge from the server
 *  ... other config
 * })
 */
export class BrowserAuthenticationInitiator implements AuthenticationInitiator {
  private postMessageHandler: null | ((event: MessageEvent) => void) = null;

  protected config: {
    clientId: string;
    redirectUrl: string;
    state: string;
    scopes: string[];
    // determines whether to trigger the login/logout in an iframe, a new browser window, or redirect the current one.
    displayMode: DisplayMode;
    oauthServer: string;
    // the endpoints to use for the login (if not obtained from the auth server
    endpointOverrides?: Partial<Endpoints>;
    // used to get the PKCE challenge
    pkceConsumer: PKCEConsumer;
    // the nonce to use for the login
    nonce?: string;
  };

  constructor(config: typeof this.config) {
    this.config = config;
  }

  async handleLoginAppPopupFailed(redirectUrl: string) {
    console.warn(
      "Login app popup failed open a popup, using redirect mode instead...",
      redirectUrl,
    );
    window.location.href = redirectUrl;
  }

  // Use the config (Client ID, scopes OAuth Server, Endpoints, PKCEConsumer) to generate a new login url
  // and then use the display mode to decide how to send the user there
  async signIn(iframeRef: HTMLIFrameElement | null): Promise<URL> {
    const url = await generateOauthLoginUrl(this.config);

    this.postMessageHandler = (event: MessageEvent) => {
      const thisURL = new URL(window.location.href);
      if (
        event.origin.endsWith("civic.com") ||
        thisURL.hostname === "localhost"
      ) {
        if (!validateLoginAppPostMessage(event.data, this.config.clientId)) {
          return;
        }
        const loginMessage = event.data as LoginPostMessage;
        this.handleLoginAppPopupFailed(loginMessage.data.url);
      }
    };

    window.addEventListener("message", this.postMessageHandler);

    if (this.config.displayMode === "iframe") {
      if (!iframeRef)
        throw new Error("iframeRef is required for displayMode 'iframe'");
      iframeRef.setAttribute("src", url.toString());
    }

    if (this.config.displayMode === "redirect") {
      window.location.href = url.toString();
    }

    if (this.config.displayMode === "new_tab") {
      try {
        const popupWindow = window.open(url.toString(), "_blank");
        if (!popupWindow) {
          throw new PopupError("Failed to open popup window");
        }
      } catch (error) {
        console.error("popupWindow", error);
        throw new PopupError(
          "window.open has thrown: Failed to open popup window",
        );
      }
    }

    return url;
  }

  async signOut(): Promise<URL> {
    const localStorage = new LocalStorageAdapter();
    await clearTokens(localStorage);
    await clearUser(localStorage);
    // TODO open the iframe or new tab etc: the logout URL is not currently
    // supported by on the oauth, so just clear state until then
    const url = await generateOauthLogoutUrl(this.config);
    return url;
  }

  cleanup() {
    if (this.postMessageHandler) {
      window.removeEventListener("message", this.postMessageHandler);
    }
  }
}

/** A general-purpose authentication initiator, that just generates urls, but lets
 * the caller decide how to use them. This is useful for server-side applications
 * that may serve this URL to their front-ends or just call them directly
 */
export class GenericAuthenticationInitiator implements AuthenticationInitiator {
  protected config: {
    clientId: string;
    redirectUrl: string;
    state: string;
    scopes: string[];
    oauthServer: string;
    nonce?: string;
    // the endpoints to use for the login (if not obtained from the auth server)
    endpointOverrides?: Partial<Endpoints>;
    // used to get the PKCE challenge
    pkceConsumer: PKCEConsumer;
  };

  constructor(config: typeof this.config) {
    this.config = config;
  }

  // Use the config (Client ID, scopes OAuth Server, Endpoints, PKCEConsumer) to generate a new login url
  // and simply return the url
  async signIn(): Promise<URL> {
    return generateOauthLoginUrl(this.config);
  }

  async signOut(): Promise<URL> {
    return generateOauthLogoutUrl(this.config);
  }
}

type BrowserAuthenticationConfig = {
  clientId: string;
  redirectUrl: string;
  scopes: string[];
  oauthServer: string;
  endpointOverrides?: Partial<Endpoints>;
  displayMode: DisplayMode;
};

/**
 * An authentication resolver that can run on the browser (i.e. a public client)
 * It uses PKCE for security. PKCE and Session data are stored in local storage
 */
export class BrowserAuthenticationService extends BrowserAuthenticationInitiator {
  private oauth2client: OAuth2Client | undefined;
  private endpoints: Endpoints | undefined;

  // TODO WIP - perhaps we want to keep resolver and initiator separate here
  constructor(
    config: BrowserAuthenticationConfig,
    // Since we are running fully on the client, we produce as well as consume the PKCE challenge
    protected pkceProducer = new BrowserPublicClientPKCEProducer(),
  ) {
    super({
      ...config,
      state: generateState(config.displayMode),
      // Store and retrieve the PKCE challenge in local storage
      pkceConsumer: pkceProducer,
    });
  }

  // TODO too much code duplication here between the browser and the server variant.
  // Suggestion for refactor: Standardise the config for AuthenticationResolvers and create a one-shot
  // function for generating an oauth2client from it
  async init(): Promise<this> {
    // resolve oauth config
    this.endpoints = await getEndpointsWithOverrides(
      this.config.oauthServer,
      this.config.endpointOverrides,
    );
    this.oauth2client = new OAuth2Client(
      this.config.clientId,
      this.endpoints.auth,
      this.endpoints.token,
      {
        redirectURI: this.config.redirectUrl,
      },
    );

    return this;
  }

  // Two responsibilities:
  // 1. resolve the auth code to get the tokens (should use library code)
  // 2. store the tokens in local storage
  async tokenExchange(
    code: string,
    state: string,
  ): Promise<OIDCTokenResponseBody> {
    if (!this.oauth2client) await this.init();
    const codeVerifier = await this.pkceProducer.getCodeVerifier();
    if (!codeVerifier) throw new Error("Code verifier not found in storage");

    // exchange auth code for tokens
    const tokens = await exchangeTokens(
      code,
      state,
      this.pkceProducer,
      this.oauth2client!, // clean up types here to avoid the ! operator
      this.config.oauthServer,
      this.endpoints!, // clean up types here to avoid the ! operator
    );

    await storeTokens(new LocalStorageAdapter(), tokens);

    // cleanup the browser window if needed
    const parsedDisplayMode = displayModeFromState(
      state,
      this.config.displayMode,
    );

    if (parsedDisplayMode === "new_tab") {
      // Close the popup window
      window.close();
    }
    // these are the default oAuth params that get added to the URL in redirect which we want to remove if present
    removeParamsWithoutReload(DEFAULT_OAUTH_GET_PARAMS);
    return tokens;
  }

  // Get the session data from local storage
  async getSessionData(): Promise<SessionData | null> {
    const storageData = await retrieveTokens(new LocalStorageAdapter());

    if (!storageData) return null;

    return {
      authenticated: !!storageData.id_token,
      idToken: storageData.id_token,
      accessToken: storageData.access_token,
      refreshToken: storageData.refresh_token,
    };
  }

  async validateExistingSession(): Promise<SessionData> {
    try {
      const sessionData = await this.getSessionData();
      if (!sessionData?.idToken || !sessionData.accessToken) {
        const unAuthenticatedSession = { ...sessionData, authenticated: false };
        await clearTokens(new LocalStorageAdapter());
        return unAuthenticatedSession;
      }
      if (!this.endpoints || !this.oauth2client) await this.init();

      // this function will throw if any of the tokens are invalid
      await validateOauth2Tokens(
        {
          access_token: sessionData.accessToken,
          id_token: sessionData.idToken,
          refresh_token: sessionData.refreshToken,
        },
        this.endpoints!,
        this.oauth2client!,
        this.config.oauthServer,
      );
      return sessionData;
    } catch (error) {
      console.warn("Failed to validate existing tokens", error);
      const unAuthenticatedSession = {
        authenticated: false,
      };
      await clearTokens(new LocalStorageAdapter());
      return unAuthenticatedSession;
    }
  }

  static async build(
    config: BrowserAuthenticationConfig,
  ): Promise<AuthenticationResolver> {
    const resolver = new BrowserAuthenticationService(config);
    await resolver.init();

    return resolver;
  }
}
