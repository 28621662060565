"use client";

import React, { useCallback, useEffect, useRef, useState } from "react";
import { LoadingIcon } from "@/shared/components/LoadingIcon.js";
import { CloseIcon } from "@/shared/components/CloseIcon.js";
import { CivicAuthIframe } from "@/shared/components/CivicAuthIframe.js";
import { useAuth, useConfig, useIframe } from "@/shared/hooks/index.js";
import { TOKEN_EXCHANGE_TRIGGER_TEXT } from "@/constants.js";

type CivicAuthIframeContainerProps = {
  onClose?: () => void;
  closeOnRedirect?: boolean;
};

function NoChrome({
  children,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) {
  return <div style={{ position: "relative" }}>{children}</div>;
}

function IframeChrome({
  children,
  onClose,
}: {
  children: React.ReactNode;
  onClose?: () => void;
}) {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        zIndex: 50,
        display: "flex",
        height: "100vh",
        width: "100vw",
        minWidth: "18rem",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(17, 24, 39, 0.5)",
      }}
      onClick={onClose}
    >
      <div
        style={{
          position: "relative",
          overflow: "hidden",
          borderRadius: "1.5rem",
          backgroundColor: "white",
          padding: "1.5rem",
          boxShadow:
            "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          style={{
            position: "absolute",
            right: "1rem",
            top: "1rem",
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
            justifyContent: "center",
            border: "none",
            backgroundColor: "transparent",
            padding: "0.25rem",
            color: "#9ca3af",
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </button>

        {children}
      </div>
    </div>
  );
}
const CivicAuthIframeContainer = ({
  onClose,
  closeOnRedirect = true,
}: CivicAuthIframeContainerProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isLoading: isAuthLoading } = useAuth();
  const config = useConfig();
  const { setAuthResponseUrl, iframeRef } = useIframe();

  const processIframeUrl = useCallback(() => {
    if (iframeRef && iframeRef.current && iframeRef.current.contentWindow) {
      try {
        const iframeUrl = iframeRef.current.contentWindow.location.href;
        // we know that oauth has finished when the iframe redirects to our redirectUrl
        if (iframeUrl.startsWith(config.redirectUrl)) {
          // we still want to show the spinner during redirect
          setIsLoading(true);
          const iframeBody =
            iframeRef.current.contentWindow.document.body.innerHTML;

          // If we're doing a server token exchange, we need to call the server a second time
          // using a fetch so that we're on the same domain and cookies can be sent and read
          // The server will use the presence of the code_verifier cookie to determine whether to do a token exchange or not.
          // On the initial (3rd party) redirect from the auth server, the cookie won't be sent, so the server-side callback route will just render a blank page,
          // and we'll do the exchange request from here, which will include the cookies.
          if (iframeBody.includes(TOKEN_EXCHANGE_TRIGGER_TEXT)) {
            const params = new URL(iframeUrl).searchParams;
            const appUrl = globalThis.window?.location?.origin;
            fetch(
              `${config.redirectUrl}?${params.toString()}&appUrl=${appUrl}`,
            );
          } else {
            // if we're doing token-exchange in the client, we can just set the authResponseUrl
            // to be handled by the auth provider
            setAuthResponseUrl(iframeUrl);
          }

          if (closeOnRedirect) onClose?.();
          return true; // Successfully processed the URL
        }
      } catch {
        // If we get here, the iframe hasn't redirected to our origin yet
        console.log("Waiting for redirect...");
      }
    }
    return false; // Haven't processed the URL yet
  }, [
    closeOnRedirect,
    config.redirectUrl,
    iframeRef,
    onClose,
    setAuthResponseUrl,
  ]);

  const intervalId = useRef<NodeJS.Timeout>();

  const handleEscape = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.();
      }
    },
    [onClose],
  );

  // handle Escape
  useEffect(() => {
    window.addEventListener("keydown", handleEscape);

    return () => window.removeEventListener("keydown", handleEscape);
  });

  const handleIframeLoad = useCallback(() => {
    setIsLoading(false);

    const iframeHasUrl = processIframeUrl();

    if (iframeHasUrl && intervalId.current) {
      clearInterval(intervalId.current);
    }
  }, [processIframeUrl, setIsLoading, intervalId]);

  const showLoadingIcon = isLoading || isAuthLoading;

  const WrapperComponent = config.modalIframe ? IframeChrome : NoChrome;

  return (
    <WrapperComponent onClose={onClose}>
      {showLoadingIcon ? (
        <div
          id="civic-auth-loading-icon-wrapper"
          style={{
            position: "absolute",
            inset: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <LoadingIcon />
        </div>
      ) : null}

      <CivicAuthIframe ref={iframeRef} onLoad={handleIframeLoad} />
    </WrapperComponent>
  );
};

export type { CivicAuthIframeContainerProps };

export { CivicAuthIframeContainer };
