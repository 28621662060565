/**
 * Checks if a popup window is blocked by the browser.
 *
 * This function attempts to open a small popup window and then checks if it was successfully created.
 * If the popup is blocked by the browser, the function returns `true`. Otherwise, it returns `false`.
 *
 * @returns {boolean} - `true` if the popup is blocked, `false` otherwise.
 */
const isPopupBlocked = (): boolean => {
  // First we try to open a small popup window. It either returns a window object or null.
  const popup = window.open("", "", "width=1,height=1");

  // If window.open() returns null, popup is definitely blocked
  if (!popup) {
    return true;
  }

  try {
    // Try to access a property of the popup to check if it's usable
    if (typeof popup.closed === "undefined") {
      throw new Error("Popup is blocked");
    }
  } catch {
    // Accessing the popup's properties throws an error if the popup is blocked
    return true;
  }

  // Close the popup immediately if it was opened
  popup.close();
  return false;
};

// This type narrows T as far as it can by:
// - removing all keys where the value is `undefined`
// - making keys that are not undefined required
// So, for example: given { a: string | undefined, b: string | undefined },
// if you pass in { a: "foo" }, it returns an object of type: { a: string }
type WithoutUndefined<T> = {
  [K in keyof T as undefined extends T[K] ? never : K]: T[K];
};
export const withoutUndefined = <T extends { [K in keyof T]: unknown }>(
  obj: T,
): WithoutUndefined<T> => {
  const result = {} as WithoutUndefined<T>;

  for (const key in obj) {
    if (obj[key] !== undefined) {
      // TypeScript needs assurance that key is a valid key in WithoutUndefined<T>
      // We use type assertion here
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (result as any)[key] = obj[key];
    }
  }

  return result;
};

export { isPopupBlocked };
