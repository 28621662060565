import type { AuthStorage } from "@/types.js";

export class LocalStorageAdapter implements AuthStorage {
  async get(key: string): Promise<string> {
    return Promise.resolve(localStorage.getItem(key) || "");
  }

  async set(key: string, value: string): Promise<void> {
    localStorage.setItem(key, value);
  }
}
