"use client";
import React from "react";
import type { DisplayMode } from "@/types.js";
import { useUser } from "@/reactjs/hooks/useUser.js";

const SignInButton = ({
  displayMode,
  className,
}: {
  displayMode?: DisplayMode;
  className?: string;
}) => {
  const { signIn } = useUser();

  return (
    <button
      data-testid="sign-in-button"
      style={{
        borderRadius: "9999px",
        border: "1px solid #6b7280",
        padding: "0.75rem 1rem",
        transition: "background-color 0.2s",
      }}
      className={className}
      onClick={() => signIn(displayMode)}
    >
      Sign In
    </button>
  );
};

export { SignInButton };
