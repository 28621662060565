"use client";
import type { Dispatch, ReactNode, RefObject, SetStateAction } from "react";
import React, { createContext } from "react";

export type IframeProviderOutput = {
  iframeRef: RefObject<HTMLIFrameElement> | null;
  setAuthResponseUrl: Dispatch<SetStateAction<string | null>>;
};
const defaultIframe: IframeProviderOutput = {
  iframeRef: null,
  setAuthResponseUrl: () => {},
};

// Context for exposing Iframe specifically to the TokenProvider
const IframeContext = createContext<IframeProviderOutput>(defaultIframe);

type IframeContextType = {
  children: ReactNode;
  iframeRef: RefObject<HTMLIFrameElement> | null;
  setAuthResponseUrl: Dispatch<SetStateAction<string | null>>;
};

const IframeProvider = ({
  children,
  iframeRef,
  setAuthResponseUrl,
}: IframeContextType) => (
  <IframeContext.Provider value={{ iframeRef, setAuthResponseUrl }}>
    {children}
  </IframeContext.Provider>
);

export type { IframeContextType };
export { IframeProvider, IframeContext };
