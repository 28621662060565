import { deriveCodeChallenge } from "@/shared/lib/util.js";
import { generateCodeVerifier } from "oslo/oauth2";
import { LocalStorageAdapter } from "@/browser/storage.js";
import type { PKCEConsumer, PKCEProducer } from "@/services/types.js";
import type { AuthStorage } from "@/types.js";
import { CodeVerifier } from "@/shared/lib/types.js";

/** A PKCE consumer that retrieves the challenge from a server endpoint */
export class ConfidentialClientPKCEConsumer implements PKCEConsumer {
  constructor(private pkceChallengeEndpoint: string) {}
  async getCodeChallenge(): Promise<string> {
    const response = await fetch(
      `${this.pkceChallengeEndpoint}?appUrl=${window.location.origin}`,
    );
    const data = (await response.json()) as { challenge: string };
    return data.challenge;
  }
}

/** A PKCE Producer that can generate and store a code verifier, but is agnostic as to the storage location */
export class GenericPublicClientPKCEProducer implements PKCEProducer {
  constructor(private storage: AuthStorage) {}

  // if there is already a verifier, return it,
  // If not, create a new one and store it
  async getCodeChallenge(): Promise<string> {
    // let verifier = await this.getCodeVerifier();
    // if (!verifier) {
    const verifier = generateCodeVerifier();
    this.storage.set(CodeVerifier.COOKIE_NAME, verifier);
    // }
    return deriveCodeChallenge(verifier);
  }
  // if there is already a verifier, return it,
  async getCodeVerifier(): Promise<string | null> {
    return this.storage.get(CodeVerifier.COOKIE_NAME);
  }
}

/** A PKCE Producer that is expected to run on a browser, and does not need a backend */
export class BrowserPublicClientPKCEProducer extends GenericPublicClientPKCEProducer {
  constructor() {
    super(new LocalStorageAdapter());
  }
}
