"use client";
import React from "react";
import {
  AuthProvider,
  type AuthProviderProps,
} from "@/shared/providers/AuthProvider.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserPublicClientPKCEProducer } from "@/services/PKCE.js";
import { UserProvider } from "./UserProvider.js";
import { LocalStorageAdapter } from "@/browser/storage.js";

const queryClient = new QueryClient();

type CivicAuthProviderProps = Omit<AuthProviderProps, "pkceConsumer">;

const CivicAuthProvider = ({ children, ...props }: CivicAuthProviderProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        {...props}
        pkceConsumer={new BrowserPublicClientPKCEProducer()}
      >
        <UserProvider storage={new LocalStorageAdapter()}>
          {children}
        </UserProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
};

export { CivicAuthProvider, type CivicAuthProviderProps };
