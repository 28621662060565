import { retrieveTokens } from "@/shared/lib/util.js";
import { parseJWT } from "oslo/jwt";
import type { AuthStorage, User } from "@/types.js";

export async function getUser(storage: AuthStorage): Promise<User | null> {
  const tokens = await retrieveTokens(storage);
  if (!tokens) return null;

  // Assumes all information is in the ID token
  return (parseJWT(tokens.id_token)?.payload as User) ?? null;
}
